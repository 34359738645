<template>
  <el-tabs type="border-card" v-model="tabName" @tab-change="tabChange">
    <el-tab-pane label="打印当前面单" name="current">
      <!-- 电子面单打印 -->
      <el-table :data="printData" stripe border size="small" height="450">
        <el-table-column prop="orderNo" label="平台订单号" width="190" show-overflow-tooltip>
          <template #header>
            <el-checkbox label="全选" @change="checkAllChanges" v-model="checkAll">平台订单号</el-checkbox>
          </template>
          <template #default="scope">
            <el-checkbox-group v-model="checkList" v-if="!scope.row.expressPostNo" size="small">
              <el-checkbox v-for="value in scope.row.orderNo.split(',')" :key="value" :label="value"
                style="display: block; height: 25px; line-height: 25px;" />
            </el-checkbox-group>
          </template>
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="45"> </el-table-column>
        <el-table-column prop="dataFrom" label="订单平台" width="70" show-overflow-tooltip> </el-table-column>
        <!-- <el-table-column prop="orderNo" label="平台订单号" width="150" show-overflow-tooltip>
          <template #default="scope">
            <div v-for="value in scope.row.orderNo.split(',')" :key="value">{{ value }}</div>
          </template>
        </el-table-column> -->

        <el-table-column prop="receiverName" label="收件人" width="70" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="receiverMobile" label="手机号" width="100" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="receiverAddress" width="180" label="地址" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row.receiverProvince }}{{ scope.row.receiverCity }}
            {{ scope.row.receiverArea }}{{ scope.row.receiverTown }} {{ scope.row.receiverAddress }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="receiverZipCode" width="70" label="邮编"> </el-table-column> -->
        <el-table-column prop="quantity" label="数量" width="50"> </el-table-column>
        <el-table-column prop="expressCode" width="80" label="快递公司">
          <template #default="scope">
            <el-select v-model="scope.row.expressCode" placeholder="" size="small" style="width: 100%">
              <el-option v-for="item in dict.express" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="expressPostNo" width="90" label="快递单号" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="(scope.row.expressPostNo || '').length > 0">{{ scope.row.expressPostNo }}</span>
            <span v-else>打印后返回</span>
          </template>
        </el-table-column>
        <el-table-column prop="insureFee" width="70" label="投保金额"> </el-table-column>
        <el-table-column prop="senderName" width="90" label="发件人">
          <template #default="scope">
            <el-select v-model="scope.row.senderName" placeholder="" size="small" style="width: 100%">
              <el-option v-for="item in dict.senders" :key="item.senderName" :label="item.senderName"
                :value="item.senderName"> </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip>
          <template #default="scope">
            <div v-for="value in scope.row.remark.split('\n')" :key="value">{{ value }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="历史保存面单" name="history">
      <!-- 已经保存过的电子面单 可以重打 -->
      <el-table :data="printData" stripe border size="small" height="450">
        <el-table-column prop="orderNo" label="平台订单号" width="190" show-overflow-tooltip>
          <template #header>
            <el-checkbox label="全选" @change="checkAllChanges" v-model="checkAll">平台订单号</el-checkbox>
          </template>
          <template #default="scope">
            <el-checkbox-group v-model="checkList" v-if="!scope.row.expressPostNo" size="small">
              <el-checkbox v-for="value in scope.row.orderNo.split(',')" :key="value" :label="value"
                style="display: block; height: 25px; line-height: 25px;" />
            </el-checkbox-group>
          </template>
        </el-table-column>
        <!-- <el-table-column type="selection" width="55" align="center" /> -->
        <el-table-column type="index" align="center" label="序号" width="45"> </el-table-column>
        <el-table-column prop="dataFrom" label="订单平台" width="70" show-overflow-tooltip> </el-table-column>

        <el-table-column prop="receiverName" label="收件人" width="70" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="receiverMobile" label="手机号" width="100" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="receiverAddress" width="180" label="地址" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row.receiverProvince }}{{ scope.row.receiverCity }}
            {{ scope.row.receiverArea }}{{ scope.row.receiverTown }} {{ scope.row.receiverAddress }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="receiverZipCode" width="70" label="邮编"> </el-table-column> -->
        <el-table-column prop="quantity" label="数量" width="50"> </el-table-column>
        <el-table-column prop="status" width="50" label="状态">
          <template #default="scope">
            <span style="color:#79bbff" v-if="scope.row.status == 1">成功</span>
            <span style="color:#e6a23c" v-else-if="scope.row.status == 3">取消</span>
            <span style="color:#f56c6c" v-else>失败</span>
          </template>
        </el-table-column>
        <el-table-column prop="expressName" width="90" label="快递公司">
          <template #default="scope">
            <el-select v-model="scope.row.expressCode" placeholder="" :disabled="scope.row.status == 1" size="small"
              style="width: 100%">
              <el-option v-for="item in dict.express" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="expressPostNo" width="130" label="快递单号" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="(scope.row.expressPostNo || '').length > 0">{{ scope.row.expressPostNo }}</span>
            <span v-else>打印后返回</span>
          </template>
        </el-table-column>
        <el-table-column prop="insureFee" width="70" label="投保金额"> </el-table-column>
        <el-table-column prop="senderName" width="90" label="发件人">
          <template #default="scope">
            <el-select v-model="scope.row.senderName" placeholder="" :disabled="scope.row.status == 1" size="small"
              style="width: 100%" @change="changeSender(scope.row)">
              <el-option v-for="item in dict.senders" :key="item.senderName" :label="item.senderName"
                :value="item.senderName"> </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip>
          <template #default="scope">
            <div v-for="value in scope.row.remark.split('\n')" :key="value">{{ value }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="reason" label="" width="80" show-overflow-tooltip>
          <template #default="scope">
            <div v-if="scope.row.status == 1">
              <el-button @click="printEvent(scope.row)" type="primary" link size="small">
                [打印]
              </el-button>
            </div>
            <div v-else>{{ scope.row.reason }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { orderPrintDto, savePrintData } from "@/api/order";
export default {
  name: "OrderPostPrint",
  emits: ["freshen"],
  data() {
    return {
      tabName: "current",
      printData: [],
      checkAll: false,
      allList: [],
      checkList: [],
      dict: {
        express: [],
        senders: [
          {
            "senderName": "陈先生",
            "senderMobile": "17322331332",
            "senderProvince": "广东省",
            "senderCity": "深圳市",
            "senderArea": "罗湖区",
            "senderAddress": "东晓街道独树社区翠竹北路5号水贝石化工业区2栋2层东",
          }
        ]
      }
    };
  },
  props: {
    selects: Array,
    status: String
  },
  methods: {
    tabChange() {
      this.fetchs();

      this.checkAll = true;
      this.checkList = this.allList;
      this.nocheckedList = [];
    },
    fetchs() {
      let that = this;
      // let postData = [];
      // this.selects.forEach(element => {
      //   postData.push({ id: element.id })
      // });
      that.allList = [];
      orderPrintDto(this.tabName, this.status, this.selects).then(
        (res) => {
          if (res.code === 200) {
            this.printData = res.data;
            this.printData.forEach((res) => {
              res.senderName = this.dict.senders[0].senderName;
              res.orderNo.split(',').forEach((orderNo) => {
                if (orderNo || orderNo.length > 0)
                  that.allList.push(orderNo);
              });
            });
          }
        },
        (err) => {
          this.$alert(err.content, '提示')
        }
      )
    },
    save() {
      let checkedPrintData = [];
      //没有勾选的订单号
      let nocheckedList = this.allList.filter(item => !this.checkList.includes(item));
      this.printData.forEach((row) => {
        //勾选的打印单
        if (row.orderNo.split(',').filter(q => q.length > 0 && this.checkList.indexOf(q) > -1).length > 0) {
          row.senderName = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderName;
          row.senderMobile = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderMobile;
          row.senderProvince = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderProvince;
          row.senderCity = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderCity;
          row.senderArea = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderArea;
          row.senderAddress = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderAddress;

          if (!row.expressCode) {
            this.$alert('请选择对应的订单快递', '提示');
            return;
          }
          row.expressName = this.dict.express.filter(q => q.value == row.expressCode)[0].name;

          checkedPrintData.push(row);
        }
      });

      let tipMessage = "确认保存并打印勾选的快递单据, 是否继续?";
      if (this.tabName == 'history') {
        tipMessage += "  （仅会打印失败的发货单据）";
      }

      this.$confirm(tipMessage, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          savePrintData(checkedPrintData, nocheckedList).then(
            (res) => {
              if (res && res.code == 200) {
                this.$emit("freshen");
                this.fetchs();
              }
              this.$alert(res.content, '提示');
            },
            (err) => {
              this.$alert(err.content, '错误提示');

              //this.fetchs();
            }
          );
        })
    },
    checkAllChanges() {
      if (this.checkAll) this.checkList = this.allList;
      else this.checkList = [];
    },
    printEvent(row) {
      console.log(row);
      let htmlContent = this.$util.decodeHTML(row.templateHtml || '');
      if (htmlContent.length <= 200) {
        this.$alert('电子面单打印格式错误，请尝试重新下单或联系管理员', '提示');
        return;
      }

      let LODOP = window.LODOP;
      LODOP.PRINT_INIT("打印快递单");               //打印初始化
      //LODOP.ADD_PRINT_TEXT(0, 0, 100, 20, "文本内容一");//然后多个ADD语句及SET语句
      LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", htmlContent); //打印html

      //window.LODOP.GET_PRINTER_COUNT();  //获取打印机个数
      //window.LODOP.GET_PRINTER_NAME(1);  //获取指定的打印机名称

      LODOP.PREVIEW();                       //打印预览
      //LODOP.PRINT();                       //最后一个打印(或预览、维护、设计)语句
    }
  },
  mounted() {
    this.$util.loadJs("http://localhost:8000/CLodopfuncs.js?name=CLODOPA").then(() => {
      // 加载成功，进行后续操作
      console.log('打印组件加载成功');
    }, (err) => {
      console.log('加载失败', err);
      this.$alert('打印组件加载失败，请检查是否安装打印组件或是否开启打印组件', '提示');
    });

    this.$kaung.dictionary("stock", "express").then(res => this.dict.express = res);
    this.tabChange();
  },
};
</script>
<style lang="less"></style>