<template>
  <!-- 导入快递单信息 -->
  <div class="load-dialog" style="text-align:center; max-width:500px;margin:0px auto;">
    <div class="download" style="margin:10px auto;">点击下载模板：<a target="_blank" :href="templateUrl"> 订单发货快递单导入模板.xlsx </a> </div>
    <el-upload v-loading="loading" class="upload-box" ref="upload" action="#" drag :on-change="handleChange" :on-remove="handleRemove" :on-exceed="handleExceed" :file-list="fileList" :http-request="submitUpload" :auto-upload="true"
      :limit="1">
      <el-icon style="font-size:3em;">
        <UploadFilled />
      </el-icon>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip">
        注：Excel表格里的填写项请严格按照模板内容进行填写
      </div>
    </el-upload>

    <div class="errtext" v-for="(value, key) in errorlist" :key="key">
      <el-icon>
        <CloseBold />
      </el-icon>
      {{ value }}
    </div>
  </div>
</template>
<script>
import { uploader } from "@/api/subupload";
import { orderPostInfo } from "@/api/order";
export default {
  name: "OrderPost",
  emits: ["freshen"],
  data() {
    return {
      loading: false,
      templateUrl: process.env.VUE_APP_BASEURL + "/static/template/OrderPost.xlsx",
      errorlist: [],
      fileList: [],
    };
  },
  methods: {
    //添加文件事件
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    //删除文件事件
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    //超出个数事件
    handleExceed(file, fileList) {
      this.$message({
        message: "每次只能上传一个文件",
        type: "warning",
      });
    },

    //上传操作
    submitUpload(file) {
      console.log('upload');
      this.loading = true;
      var dataList = new FormData();
      dataList.append(file.file.name, file.file);
      dataList.append("fileType", "excel");
      dataList.append("dataType", "ImportOrderPost");
      this.$refs.upload.clearFiles();

      uploader(dataList)
        .then((res) => {
          if (res.code === 200) {
            this.$message({ message: "上传成功", type: "success", });
            this.dataId = res.data[0].id;
          } else {
            this.$message.error("上传失败，请重试");
          }
        })
        .then(() => {
          this.loading = false;
          orderPostInfo(this.dataId).then(
            (res) => {
              if (res.code != 200) {
              }
              this.errorlist = Object.values({ ...res.data });
              this.$emit("freshen");
              this.$alert(res.content, '提示')
            },
            (err) => {
              this.errorlist = Object.values({ ...err.data });
              //this.resList = err.data;
              this.$alert(err.content, '提示')
            }
          );
        });

      this.fileList = [];
    }
  },
  mounted() {

  },
};
</script>
<style lang="less">
</style>