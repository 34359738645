<template>
  <el-form :model="form" label-width="120px" :rules="rules" ref="form">
    <el-form-item label="快递单号" prop="postNo">
      <el-input v-model="form.postNo" ref="postNo" style="max-width:280px;" @keyup.enter="submitEvent" />
    </el-form-item>
    <el-form-item label="货号" prop="serialCode">
      <el-input v-model="form.serialCode" ref="serialCode" style="max-width:220px;" @keyup.enter="submitEvent" />
      <div style="margin-left:8px;">合并多个货品的发货请用逗号,或者空格区分</div>
    </el-form-item>
    <el-form-item label="">
      <p v-html="serialCodeLabel" style="line-height: 20px; margin-top: 10px;"></p>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="submitEvent">复核订单并发货</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { reviewOrder, reviewQueryOrder } from "@/api/order";
export default {
  name: "ReviewOrder",
  emits: ["freshen"],
  data() {
    return {
      form: {},

      rules: {
        serialCode: [
          { required: true, message: "请输入或者扫码枪扫描货品批次号", trigger: "blur" },
        ],
        postNo: [
          { required: true, message: "请输入或者扫码枪扫描快递单号", trigger: "blur" },
        ]
      },
      serialCodeLabel: ''
    };
  },
  methods: {
    submitEvent() {
      if ((this.form.serialCode || '').length == 0) { }
      this.$refs.serialCode.focus();
      if ((this.form.postNo || '').length == 0)
        this.$refs.postNo.focus();

      this.queryOrder(this.form.serialCode || this.form.postNo || '');

      let that = this;

      this.$refs.form.validate((valid) => {
        if (!valid) return;

        reviewOrder(that.form.serialCode, that.form.postNo).then((res) => {
          that.$message({ type: res.type, message: res.content, });
          that.form.serialCode = that.form.postNo = '';
          that.$emit("freshen");
        },
          (err) => {
            this.$alert(err.content, '提示')
          });
      });
    },
    queryOrder(keyword) {
      if (keyword.length > 0) {
        reviewQueryOrder(keyword).then(res => {
          console.log(res);
          this.serialCodeLabel = '';
          res.data.forEach(item => {
            this.serialCodeLabel += '<div>快递单号：' + (item.postNo || '无') + '、货号：' + (item.serialCode || '') + '；</div>';
          });
        });
      }
    }
  },
  mounted() {

  },
};
</script>
<style lang="less"></style>